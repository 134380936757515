<template>
  <div class="van-main external-detail">
    <div class="area-title">
      <p class="title">
        对外担保信息
      </p>
    </div>
    <div class="van-common-detail">
      <van-cell-group>
        <van-cell title="担保人名称" :value="utils.isEffectiveCommon(externalForm.guarantorName)" />
        <van-cell title="被担保方" :value="utils.isEffectiveCommon(externalForm.orgName)" />
        <van-cell title="担保方式" :value="utils.statusFormat(externalForm.guaranteeMethod, 'guaranteeMethod')" />
        <van-cell title="授信机构" :value="utils.isEffectiveCommon(externalForm.creditInstitutions)" />
        <van-cell title="分支机构" :value="utils.isEffectiveCommon(externalForm.branch)" />
        <van-cell title="担保金额（元）" :value="utils.moneyFormat(externalForm.guaranteeAmount)" />
        <van-cell title="担保期限" :value="utils.isEffectiveCommon(externalForm.guaranteePeriod)" />
        <van-cell title="担保起始日" :value="utils.dateFormat(externalForm.guaranteeDateStart)" />
        <van-cell title="担保到期日" :value="utils.dateFormat(externalForm.guaranteeDateEnd)" />
        <van-cell title="担保合同编号" :value="utils.isEffectiveCommon(externalForm.guaranteeContractNo)" />
        <van-cell title="担保合同名称" :value="utils.isEffectiveCommon(externalForm.guaranteeContractName)" />
        <van-cell title="主合同编号" :value="utils.isEffectiveCommon(externalForm.mainContractNo)" />
        <van-cell title="主合同名称" :value="utils.isEffectiveCommon(externalForm.mainContractName)" />
        <file-list :file-list="fileList" title="对外担保合同" />
      </van-cell-group>
    </div>
    <back-button />
  </div>
</template>

<script>
import { Cell, CellGroup, Divider, Button, Toast } from 'vant'
import BackButton from '@/components/back-button'
import fileList from '@/components/file-list'
export default {
  components: {
    BackButton,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [Toast.name]: Toast,
    fileList
  },
  data () {
    return {
      id: this.$route.query.id,
      externalForm: {
      },
      fileList: []
    }
  },
  computed: {
  },
  created () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      this.api.fundManagement.external.getDetail(this.$route.query.id).then(result => {
        this.externalForm = result.data.value
        const fileList = []
        if (this.externalForm.fileDetailInfoVo) {
          fileList.push(this.externalForm.fileDetailInfoVo)
        }
        this.fileList = fileList
      }).finally(() => {
      })
    }
  }
}
</script>

<style lang="less" scoped>
.external-detail {
  margin-bottom: 60px;
}
</style>
