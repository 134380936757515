var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main external-detail" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "van-common-detail" },
        [
          _c(
            "van-cell-group",
            [
              _c("van-cell", {
                attrs: {
                  title: "担保人名称",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.externalForm.guarantorName
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "被担保方",
                  value: _vm.utils.isEffectiveCommon(_vm.externalForm.orgName)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "担保方式",
                  value: _vm.utils.statusFormat(
                    _vm.externalForm.guaranteeMethod,
                    "guaranteeMethod"
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "授信机构",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.externalForm.creditInstitutions
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "分支机构",
                  value: _vm.utils.isEffectiveCommon(_vm.externalForm.branch)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "担保金额（元）",
                  value: _vm.utils.moneyFormat(_vm.externalForm.guaranteeAmount)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "担保期限",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.externalForm.guaranteePeriod
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "担保起始日",
                  value: _vm.utils.dateFormat(
                    _vm.externalForm.guaranteeDateStart
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "担保到期日",
                  value: _vm.utils.dateFormat(_vm.externalForm.guaranteeDateEnd)
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "担保合同编号",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.externalForm.guaranteeContractNo
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "担保合同名称",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.externalForm.guaranteeContractName
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "主合同编号",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.externalForm.mainContractNo
                  )
                }
              }),
              _c("van-cell", {
                attrs: {
                  title: "主合同名称",
                  value: _vm.utils.isEffectiveCommon(
                    _vm.externalForm.mainContractName
                  )
                }
              }),
              _c("file-list", {
                attrs: { "file-list": _vm.fileList, title: "对外担保合同" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("back-button")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "area-title" }, [
      _c("p", { staticClass: "title" }, [_vm._v(" 对外担保信息 ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }